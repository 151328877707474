<template>
    <AppPanel subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/EPI">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/EPI"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="epi-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="field">
                        <label for="name">Nome</label>
                        <InputText
                            id="nome"
                            v-model.trim="form.nome"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nome }"
                        />
                    </div>
                    <div class="field">
                        <label for="descricao">Descrição</label>
                        <InputText
                            id="descricao"
                            v-model.trim="form.descricao"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.descricao }"
                        />
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import SesmtService from '../../services/SesmtService';
export default {
    data() {
        return {
            form: {},
            submitted: false,
            service: null
        };
    },
    created() {
        this.service = new SesmtService('/epi');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editando EPI' : 'Adicionando EPI';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
        }
    },
    components: { AppInfoManual }
};
</script>
